#vbCloseLink {
    @extend .vb-hover-animated;

    display: inline-block;
    float: right;
    text-transform: uppercase;
    line-height: 1em;
    padding-left: 5px;
    text-decoration: none;
    font-size: 0.75 * $base-font-size;
    margin: -5px -5px 10px 10px;
    color: inherit;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primary-light;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.5 * $base-font-size;
        padding-left: 4px;
    }

    @include transition-delay(inherit);
}

#vbCloseText {
    display: inline-block;
    vertical-align: middle;
}
