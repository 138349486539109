#vbBottomContainer {
    width: 100%;
    position: absolute;
    color: $color-contrast-light;
    max-height: 0;
    overflow: hidden;
    background-color: $color-contrast-dark;
    opacity: 0.5;
    pointer-events: all;
    z-index: 5;
    font-size: 0.625 * $base-font-size;
    box-shadow: 0px 0px 40px -5px $color-contrast-dark;

    &.visible {
        max-height: 200px;

        @include transition(opacity $transition-duration 0.1s ease-in-out);

        &:hover,
        &:focus {
            opacity: 1;

            @include transition-delay(0s);
        }
    }
}

#vbBottom {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 15px 15px 0;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
}

#vbCaption {
    margin-bottom: 10px;
    display: block;
}
