#vbResponsive {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
    background-color: $color-contrast-dark;
    pointer-events: all;
    z-index: 10;
    box-shadow: 0px 0px 40px -5px $color-contrast-dark;
}
