.video-js .vjs-control-bar {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2 * $base-font-size;
    z-index: 3;
    background: rgba($color-contrast-dark, $overlay-opacity);
    visibility: hidden;
    opacity: 0;

    @include align-items(center);

    @include display(flex);

    @include transition(all $transition-duration ease-in-out);
}

.vjs-has-started .vjs-control-bar {
    visibility: visible;
    opacity: 1;
}

.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-audio) .vjs-control-bar {
    visibility: hidden;
    opacity: 0;
}

.vjs-control {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: $color-contrast-light;
    height: 2 * $base-font-size;

    @include display(flex);

    @include align-items(center);

    &:before {
        display: inline-block;
        vertical-align: middle;
        width: 2 * $base-font-size;
        height: 2 * $base-font-size;
        text-align: center;
        line-height: 2 * $base-font-size;
        cursor: pointer;
    }
}

.vjs-live-control,
.vjs-custom-control-spacer,
.vjs-playback-rate,
.vjs-chapters-button,
.vjs-subtitles-button,
.vjs-captions-button,
.vjs-descriptions-button,
.vjs-audio-button {
    display: none;
}
