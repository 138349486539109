@import "bourbon";

$primary-color: #005723;
$primary-light: #84d1a4;
$overlay-opacity: 0.7;
$transition-duration: 0.5s;
$hover-duration: 0.2s;
$base-font-size: 24px;
$color-contrast-light: #ffffff;
$color-contrast-dark: #000000;

@import "icons";
@import "helpers";

@import "common/thumbnail";
@import "common/single-video";
@import "common/gallery";
@import "common/pagination";

@import "videobox/overlay";
@import "videobox/wrap";
@import "videobox/center";
@import "videobox/responsive";
@import "videobox/video";
@import "videobox/bottom";
@import "videobox/close";

@import "vbinline/wrap";
@import "vbinline/responsive";
@import "vbinline/video";
@import "vbinline/close";

@import "vbslider/wrap";
@import "vbslider/content";
@import "vbslider/buttons";

@import "video-js/base";
@import "video-js/control-bar";
@import "video-js/poster";
@import "video-js/video";
@import "video-js/play-button";
@import "video-js/spinner";

@import "video-js/controls/play";
@import "video-js/controls/volume";
@import "video-js/controls/time";
@import "video-js/controls/progress";
@import "video-js/controls/fullscreen";
