#vbiResponsive {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
    background-color: $color-contrast-dark;
    padding-bottom: 56.25%;
    pointer-events: all;
}
