.vjs-big-play-button {
    @extend .vb-icon;

    @extend .vb-hover-animated;

    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    z-index: 5;
    margin: -5 / 3 * $base-font-size;
    border: none;
    padding: 0;
    width: 10 / 3 * $base-font-size;
    height: 10 / 3 * $base-font-size;
    color: $color-contrast-light;
    background-color: rgba($color-contrast-dark, $overlay-opacity);
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 0px 40px -5px $color-contrast-dark;

    &:before {
        content: $font-Videobox-play;
        font-size: 2 * $base-font-size;
    }

    &:hover,
    &:focus {
        color: $primary-light;
    }
}

.vjs-has-started .vjs-big-play-button {
    display: none;
}
