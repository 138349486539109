.vjs-loading-spinner {
    @extend .vb-icon;

    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    z-index: 5;
    margin: -5 / 3 * $base-font-size;
    border: none;
    padding: 0;
    width: 10 / 3 * $base-font-size;
    height: 10 / 3 * $base-font-size;
    display: none;

    &:before {
        display: block;
        content: $font-Videobox-circle;
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 10 / 3 * $base-font-size;
        color: rgba($color-contrast-dark, 0);
        text-shadow: 0px 0px (5 * $base-font-size) rgba($color-contrast-dark, 0.7);
        z-index: 2;
    }

    &:after {
        display: block;
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("/images/spinner.svg") no-repeat;
        background-position: 50% 50%;
        background-size: 100% 100%;
        z-index: 3;
    }
}

.vjs-seeking, .vjs-waiting {
    .vjs-loading-spinner {
        display: block;
    }
}
