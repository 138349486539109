.vb_video_sizer {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    background-color: $color-contrast-dark;

    iframe {
        background-color: $color-contrast-dark;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.vb_video_frame {
    display: block;
    width: 100%;
    margin: 30px 0;
    text-align: center;
    font-size: 1.2em;
    color: $primary-color;
}
