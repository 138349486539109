html.vb-html5-player,
body.vb-html5-player {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background: $color-contrast-dark;

    *:focus {
        border: none;
        outline: none;
    }
}

#vb-html5-video {
    width: 100%;
    height: 100%;
    background: $color-contrast-dark;
}

.video-js {
    color: $color-contrast-light;
    background: $color-contrast-dark;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 0.625 * $base-font-size;
    line-height: 1em;
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;

    @include user-select(none);
}

.video-js .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.vjs-hidden {
    display: none;
}
